import { ISolutions } from '../components/SolutionsQuiz/Types';

const getSolutions = (subcategoryLink: string) => {
  const solutions: ISolutions = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('solutions'));
  let currentGroup: string = null;
  let isContains = false;

  if (solutions) {
    solutions.usage.map(({ groups, shortName }) =>
      groups.forEach(({ link }) => {
        if (subcategoryLink === link) {
          isContains = true;
          currentGroup = shortName;
        }
      })
    );
  }
  return {
    solutions,
    currentGroup,
    isContains,
  };
};

export default getSolutions;
