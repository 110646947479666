import React, { useMemo } from 'react';

import { useLocation } from '@reach/router';
import { IGoods } from '../Types';
import ProductInfo from '../Product/ProductInfo';
import SolutionsItems from '../SolutionsQuiz/SolutionsItems';

import { IGoodsProps } from './Types';

import './Goods.scss';
import getSolutions from '../../utils/get-solutions';

const Goods: React.FC<IGoodsProps> = ({ goods, subcategoryLink }): JSX.Element => {
  const location = useLocation();
  const isSolutions = typeof new URLSearchParams(location.search).get('solutions') === 'string';

  const { currentGroup, solutions } = getSolutions(subcategoryLink);

  const goodsItems: IGoods[] = useMemo(() => {
    const { inStock, other } = goods.reduce(
      ({ inStock, other }, cur) => {
        if (cur.productAvailable?.value === 'IN_STOCK') {
          inStock.push(cur);
        } else {
          other.push(cur);
        }
        return { inStock, other };
      },
      { inStock: [], other: [] }
    );
    return [...inStock, ...other];
  }, [goods]);

  return (
    <div className={`goods-container ${isSolutions ? 'with-solutions' : ''}`}>
      <div className="goods-wrapper">
        {goodsItems.map(
          ({
            name: { value: name },
            id,
            description: { value: description },
            price: { value: price },
            price2,
            price3,
            price4,
            measurment,
            measurment2,
            measurment3,
            measurment4,
            productAvailable,
            stock,
            link,
            images: { value: images },
            image: { value: image },
            specifications,
            brand,
            code: { value: code },
            subcategory: {
              value: {
                category: {
                  value: {
                    title: { value: categoryTitle },
                  },
                },
              },
            },
          }) => (
            <ProductInfo
              key={id}
              name={name}
              description={description}
              price={price}
              price2={price2?.value || ''}
              price3={price3?.value || ''}
              price4={price4?.value || ''}
              measurment={measurment?.value || ''}
              measurment2={measurment2?.value || ''}
              measurment3={measurment3?.value || ''}
              measurment4={measurment4?.value || ''}
              images={images}
              image={image}
              productAvailable={productAvailable?.value || null}
              code={code}
              link={link.value}
              stock={stock?.value || ''}
              id={id}
              brand={brand?.value || null}
              specifications={specifications?.value?.data || null}
              type="good"
              category={categoryTitle}
            />
          )
        )}
      </div>

      {isSolutions && <SolutionsItems currentGroup={currentGroup} solutions={solutions} />}
    </div>
  );
};

export default Goods;
