import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import InfoBlueIcon from '../../assets/icons/info-blue.svg';
import { ISolutions, ISolutionsItemsProps } from './Types';

import './SolutionsItems.scss';

const SolutionsItems: React.FC<ISolutionsItemsProps> = ({ currentGroup, solutions }) => {
  const [openedItems, setOpenedItems] = useState<string[]>([]);
  const toggleOpened = (shortName: string) => {
    const idx = openedItems.indexOf(shortName);
    return idx === -1 ? setOpenedItems(prev => [...prev, shortName]) : setOpenedItems(prev => [...prev].filter(item => item !== shortName));
  };

  useEffect(() => {
    if (solutions) toggleOpened(currentGroup);
  }, [solutions]);

  return (
    <>
      {solutions && (
        <div className="solutions-items">
          <div className="solutions-items__title">{solutions.constructiveTitle}</div>
          {solutions.usage.map(({ title, groups, shortName }) => (
            <div className={`solutions-items__item ${openedItems.indexOf(shortName) !== -1 ? 'opened' : ''} `} key={title}>
              <div className="solutions-items__item-title" onClick={() => toggleOpened(shortName)}>
                {shortName}
              </div>
              {groups.map(({ title: subtitle, description, link }) => (
                <div className="solutions-items__subitem" key={subtitle}>
                  <div className="solutions-items__subitem-title">
                    <Link to={`${link}?solutions`}>{subtitle}</Link>
                    <div className="solutions-items__subitem-description">
                      <InfoBlueIcon /> <p>{description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SolutionsItems;
